import { template as template_39780aaa118a4cc3862499c4a8d4a71b } from "@ember/template-compiler";
const WelcomeHeader = template_39780aaa118a4cc3862499c4a8d4a71b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
