import { template as template_ec932ebb582243b7bd21dbcf5d694dbd } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_ec932ebb582243b7bd21dbcf5d694dbd(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_ec932ebb582243b7bd21dbcf5d694dbd(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_ec932ebb582243b7bd21dbcf5d694dbd(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
