import { template as template_08eb4e1213474ec5aad9d809a7f74463 } from "@ember/template-compiler";
const FKLabel = template_08eb4e1213474ec5aad9d809a7f74463(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
