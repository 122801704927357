import { template as template_9ac664bcdee94e94b7ab3153e9d1f609 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_9ac664bcdee94e94b7ab3153e9d1f609(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
